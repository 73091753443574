import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img alt="Migraleve" src="/images/logo.jpg" />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded active-trail aboutmigraines">
                      <a
                        href="/about-migraine"
                        title="About Migraines"
                        className="active about-migraine"
                      >
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a
                            href="/migraine-symptoms"
                            title="Migraine Types & Symptoms"
                            className="active"
                          >
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf potentialmigrainecauses">
                          <a
                            href="/migraine-causes"
                            title="Potential Migraine Causes"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last whatcanyoudoaboutit">
                          <a
                            href="/migraine-treatment-and-prevention"
                            title="Treatment and Relief"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf ourproducts">
                      <a href="/migraine-relief-products" title="Our Products">
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/migraine-tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf migrainetriggerdiary">
                      <a
                        href="/migraine-trigger-diary"
                        title="Migraine Trigger Diary"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">
                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-11" className="node">
                      <div className="content clear-block">
                        <h1>ABOUT MIGRAINES</h1>
                        <h2>WHO IS AFFECTED BY MIGRAINES?</h2>
                        <p>
                          Around one in seven people get migraine worldwide, with over 10 million sufferers in the UK.
                        </p>
                        <p>
                          Migraine is the third most common disease in the world (behind dental caries and tension-type headache).
                        </p>
                        <p>
                          It’s estimated that 190,000 migraine attacks occur every day in the UK.<br />
                          Over 75% of people who get migraine have at least one attack each month.
                        </p>
                        <p>People can get migraine at any age. It often starts at puberty and it usually peaks between the ages of 35 and 45 years.</p>
                        <p>It is initially more common in boys than girls but this reverses at puberty with migraine affecting three-times as many women as men.</p>

                        <div className="text-center">
                          <img
                            src="/sites/migraleve.co.uk/files/images/headache.jpg"
                            className="centered_image"
                          />
                        </div>
                        <p>
                          <strong>
                            Although migraine is not life threatening, we know
                            how it can impact on your quality of life. Which is
                            why MIGRALEVE™ products work to treat symptoms of
                            migraine and provide relief. Click
                            <a
                              href="/migraine-relief-products"
                              title="migraine-relief-products"
                            >
                              {" "}
                              here{" "}
                            </a>
                            to find out which treatment option may be best for
                            you.
                          </strong>
                        </p>


                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-5" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img alt src="/images/ma.png" />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Tips" href="/tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img alt src="/images/bm.png" />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="trigger_diaryBg">
                          <div className="inner">
                            <img alt src="/images/td.png" />
                            <div>
                              <h2>trigger diary</h2>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                title="Try a trigger diary"
                                href="/trigger-diary"
                              >
                                Try a trigger diary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="info-section">
                    <div className="row">
                      <div className="col">
                        <div className="thumbnail">
                          <img src="/sites/migraleve.co.uk/files/images/symptoms.jpg" />
                          <div className="content">
                            <h2 className="text-normal">
                              Symptoms of a Migraine
                            </h2>
                            <p>
                              Read more about the phases of a migraine, and common symptoms.
                            </p>
                            <a
                              href="/migraine-symptoms"
                              className="learnmore btn-light"
                            >
                              Learn More{" "}
                              <span className="float-right"> > </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="thumbnail">
                          <img src="/sites/migraleve.co.uk/files/images/causes.jpg" />
                          <div className="content">
                            <h2 className="text-normal">
                              Potential Migraine Causes
                            </h2>
                            <p>
                              Read more about what can trigger a migraine.
                            </p>
                            <a
                              href="/migraine-causes"
                              className="learnmore btn-light"
                            >
                              Learn More{" "}
                              <span className="float-right"> > </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="thumbnail">
                          <img src="/sites/migraleve.co.uk/files/images/treatments.jpg" />
                          <div className="content">
                            <h2 className="text-normal">
                              Treatment and Relief
                            </h2>
                            <p>
                              Read more about how you can treat and relieve your migraine.
                            </p>
                            <a
                              href="/migraine-treatment-and-prevention"
                              className="learnmore btn-light"
                            >
                              Learn More{" "}
                              <span className="float-right"> > </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
